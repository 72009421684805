import React, { useState, useMemo } from 'react';

import { Link, graphql, useStaticQuery } from 'gatsby';
import { Image, Dropdown } from 'react-bootstrap';

import BlogHeader from '../../components/Blog/BlogHeader/index';
import FlodeskModal from '../../components/Flodesk/SignupModal';
import './blog.scss';

const BlogPage = () => {
  const data = useStaticQuery(graphql`
    query unifiedQuery {
      allContentfulBlogPage {
        nodes {
          blogPageData {
            bodySection {
              pressFeaturesSection {
                title
              }
            }
          }
          heroImage {
            title
            url
          }
          featuredPosts {
            title
            slug
            heroImage {
              url
              title
            }
            publishDate
            readTime
            subjectTag
            body {
              body
              internal {
                content
              }
              childMarkdownRemark {
                html
              }
            }
          }
        }
      }
      allContentfulBlogPost(sort: { fields: [publishDate], order: DESC }) {
        nodes {
          title
          slug
          seoTitle
          seoDescription
          heroImage {
            url
            title
          }
          publishDate
          readTime
          subjectTag
          body {
            body
            internal {
              content
            }
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  `);

  const [modalShow, setModalShow] = useState(false);
  const featuredPosts = data.allContentfulBlogPage.nodes[0].featuredPosts;
  const [selectedSubject, setSelectedSubject] = useState('everything');

  const blogPostsData = data.allContentfulBlogPost.nodes;

  const filteredPosts =
    selectedSubject === 'everything'
      ? blogPostsData
      : blogPostsData.filter(post =>
        Array.isArray(post.subjectTag)
          ? post.subjectTag.includes(selectedSubject)
          : post.subjectTag.split(',').includes(selectedSubject)
      );

  const handleSubjectChange = subject => {
    setSelectedSubject(subject);
  };

  const subjectTags = useMemo(() => {
    const tags = new Set();
    blogPostsData.forEach(post => {
      if (Array.isArray(post.subjectTag)) {
        post.subjectTag.forEach(tag => tags.add(tag));
      } else {
        post.subjectTag.split(',').forEach(tag => tags.add(tag.trim()));
      }
    });
    return Array.from(tags);
  }, [blogPostsData]);

  return (
    <div className="blog-page">
      <BlogHeader />
      <div className="subject-list">
        <div className="subjects">
          {subjectTags.map((tag, index) => (
            <React.Fragment key={tag}>
              <p onClick={() => handleSubjectChange(tag)}>{tag}</p>
              {index < subjectTags.length - 1 && <span>|</span>}
            </React.Fragment>
          ))}
        </div>
      </div>

      <div className="blog-page-content">
        <Image
          src={data.allContentfulBlogPage.nodes[0].heroImage.url}
          alt={data.allContentfulBlogPage.nodes[0].heroImage.title}
          className="blog-graphic"
        />
        <div className="content-right">
          <h3>Featured Stories</h3>
          <div className="featured-posts">
            {featuredPosts.map(post => (
              <>
                <div className="featured-post" key={post.slug}>
                  <img
                    src={post.heroImage.url}
                    alt={post.heroImage.title}
                    className="post-image"
                  />
                  <div className="post-text">
                    <p className="subject-tag">
                      {/* Only display the first tag */}
                      {Array.isArray(post.subjectTag)
                        ? post.subjectTag[0]
                        : post.subjectTag.split(',')[0]}
                    </p>
                    <Link to={`/blog${post.slug}`} className="purple-link">
                      {post.title}
                    </Link>
                  </div>
                </div>
              </>
            ))}
          </div>
        </div>
      </div>
      <div className="bottom-content">
        <div className="subject-selector">
          <h3>I want to learn about</h3>
          <Dropdown>
            <Dropdown.Toggle id="dropdown">{selectedSubject}</Dropdown.Toggle>
            <Dropdown.Menu>
              {selectedSubject !== 'everything' && (
                <Dropdown.Item
                  onClick={() => handleSubjectChange('everything')}
                  className="item"
                >
                  Everything
                </Dropdown.Item>
              )}
              {subjectTags.map(
                tag =>
                  tag !== selectedSubject && (
                    <Dropdown.Item
                      key={tag}
                      onClick={() => handleSubjectChange(tag)}
                      className="item"
                    >
                      {tag}
                    </Dropdown.Item>
                  )
              )}
            </Dropdown.Menu>
          </Dropdown>
        </div>

        <div className="filtered-posts">
          {filteredPosts.map(post => (
            <div className="filtered-post" key={post.slug}>
              <img src={post.heroImage.url} alt={post.heroImage.title} />
              <div className="post-text">
                {/* Check if selectedSubject is in the post's subjectTag array, if so, display it, otherwise display the first tag */}
                <p className="subject-tag">
                  {selectedSubject !== 'everything' &&
                  Array.isArray(post.subjectTag) &&
                  post.subjectTag.includes(selectedSubject)
                    ? selectedSubject
                    : Array.isArray(post.subjectTag)
                      ? post.subjectTag[0]
                      : post.subjectTag.split(',')[0]}
                </p>
                <Link to={`/blog${post.slug}`}>{post.title}</Link>
              </div>
            </div>
          ))}
        </div>
      </div>

      <FlodeskModal show={modalShow} onHide={() => setModalShow(false)} />
    </div>
  );
};

export default BlogPage;
